import { Component, OnInit } from "@angular/core";
import { OrderService } from "src/app/shared/services/order.service";
import { CompanyService } from "src/app/shared/services/company.service";
import { LoadingService } from "src/app/shared/services/loading.service";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";

import { OrderComplete } from "src/app/shared/ui/order-complete/order-complete.component";

import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { addHours, getDay } from "date-fns";

import getProductPrice from "src/app/shared/util/getProductPrice";

@Component({
  selector: "app-order-confirmation",
  templateUrl: "./order-confirmation.component.html",
  styleUrls: ["./order-confirmation.component.scss"],
})
export class OrderConfirmationComponent implements OnInit {
  me: any;
  company;
  routeId: any;
  order: any = {};
  isCollapsed = false;
  reference;
  cupom;
  error: any = {};

  selectedAddress: any;
  minDate = new Date();
  daysDisabled = [];
  orders = [];

  isDatepickerOpen = false;

  getPrice = getProductPrice;

  constructor(
    private service: OrderService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private router: Router,
    private loadingService: LoadingService,
    private modalService: BsModalService,
    private toast: ToastrService
  ) {}

  ngOnInit() {
    this.routeId = this.route.snapshot.params.id;

    this.companyService.watchCompany().subscribe((savedCompany) => {
      this.companyService.get(savedCompany._id).subscribe((company: any) => {
        this.order.client = company._id;
        this.company = company;
        this.company.setAddress = savedCompany.setAddress
        this.get();
      });
    });
  }

  get() {
    this.service.getCart().subscribe((order) => {
      this.order = order;
      this.order.next = 0

      this.setProvider()
      
      const canPayWithCredit =
        this.company.limit - this.company.creditBill > order.total &&
        this.company.type === "pj";

      if (canPayWithCredit) order.payment = "credit";

      this.selectedAddress = this.company.setAddress
    });
  }

  setProvider() {
    let selectedOffer = this.order?.selectedOffer;

    if(selectedOffer?.providers?.length > 0) {
      selectedOffer = selectedOffer.providers[this.order.next++]

      if(this.order.next >= this.order?.selectedOffer?.providers?.length) this.order.next = false
    }

    this.order.provider = selectedOffer.provider
    this.order.total = selectedOffer.total

    this.order.offers = selectedOffer.offers.map((o) => ({
      ...this.order.products.find((cp) => cp.product === o.product),
      ...o
    }))
    this.order.comment = this.company.orderNotes

    this.setAddress(this.company.setAddress)

    window.scroll(0,0);
  }

  setMinDate(date, hours = 0, daysoff) {
    if (hours < 0) return date;

    const d = addHours(date, hours > 24 ? 24 : hours);

    if ([0, 6].includes(getDay(d)) && daysoff.includes(getDay(d))) hours += 24;

    return this.setMinDate(d, hours - 24, daysoff);
  }

  setAddress(address) {
    let city = this.service.selectCity(this.order.provider.cities, address)

    this.daysDisabled = [0, 1, 2, 3, 4, 5, 6].filter(
      (x) => !city?.days.includes(x)
    );

    this.minDate = this.setMinDate(new Date(), city?.term, this.daysDisabled);

    this.order.address = address;
    delete this.order.date;
  }

  getMinDate(provider) {
    if (!this.selectedAddress) return;

    let city = this.service.selectCity(provider.cities, this.selectedAddress)

    provider.daysDisabled = [0, 1, 2, 3, 4, 5, 6].filter(
      (x) => !city.days.includes(x)
    );

    return this.setMinDate(new Date(), city?.term, this.daysDisabled);
  }

  validateCupom() {
    this.cupom = this.cupom.toUpperCase()
    this.order.cupom = ""

    const isValid = ['BLACK100', 'BLACK150', 'TUTTI', 'GONI', 'NUTRIREDESENVOLVER', 'APPMINHAESCOLA','MINHAESCOLA','FORME','DONADEESCOLINHA','CONEEDIN','IZZAPRATUDO','VOLTEI','IZZA100','IZZA50', 'CARGO100', 'HORTI100', 'LIMPEZA100'].indexOf(this.cupom) >= 0

    this.error.cupom = !isValid

    if(isValid) this.order.cupom = this.cupom
  }

  openModal() {
    this.modalService.show(OrderComplete, {
      initialState: { type: this.company.type },
    });
  }

  async save() {
    this.error = {};
    this.error.payment = this.company.type == "pf" && !this.order.payment;
    this.error.date = !this.order.date;

    if (this.error.date || this.error.payment) return;

    if (this.company.limit > 0 && ((this.company.limit - this.company.creditBill) < this.order.total))
      return this.toast.error("Limite de crédito insuficiente");

    if (this.reference)
      this.order.comment = `Escola amiga: ${this.reference}\n\n${
        this.order.comment || ""
      }`;

    this.order.offers = this.order.offers.map((o) => {
      const product = this.order.products.find((p) => p.product === o.product);

      if (typeof o.offer === "string") return o;
      if (!product || product.amount == 0) return null;

      const selectedUnit = product.selectedUnit;
      return { offer: o._id, amount: product.amount, selectedUnit };
    });

    this.order.offers = this.order.offers.filter((o) => Boolean(o));
    this.order.products = this.order.products.filter((p) => !p.disabled);
    const { candidate, selectedOffer, productsMap, ...rest } = this.order;

    this.orders.push(rest)

    if(this.order.next) {
      delete this.order.date
      return this.setProvider();
    }

    this.orders[0].difference = selectedOffer.difference > 0 ? selectedOffer.difference : 0
    this.orders[0].cupom = this.order.cupom
    this.loadingService.show();

    for await (const order of this.orders) {
      await this.service.save(order).toPromise()
    }

    this.loadingService.hide();
    this.service.clearCart();

    this.openModal();
    this.router.navigateByUrl("/home");
  }

  saveCart() {
    this.service.setCart(this.order);
    this.service.saveCart();
  }
}
